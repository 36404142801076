import React,{useEffect,useState} from 'react';
import styled from "styled-components"
import TileDummy from "../images/banner-img1.png"
import {Link} from "gatsby"
import SectionButtonExternal from "../components/SectionButtonExternal"
const Tile = styled(Link)`
    text-decoration:none;
    cursour:pointer;
    display:flex;
    width:31.33%;
    margin:50px 1% 50px 1%;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    border-radius:30px;
    overflow:hidden;
    height:auto;
    box-shadow: 0 8px 30px -6px rgba(0,0,0,0.1);
    transition:all 0.5s ease;
    @media screen and (max-width: 991px) {
        width:48%;
    }
    @media screen and (max-width: 479px) {
        width:98%;
        margin:20px 1% 20px 1%;
    }
    &:hover{
        box-shadow: 10px 20px 40px -10px rgba(0,0,0,0.15);
    }
`;

const TileImageWrap = styled.div`
    position:relative;
    width:100%;
    height:270px;
    @media screen and (max-width: 479px) {
        height:220px;
    }
`;
const TileImage = styled.img`
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:50% 0%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-bottom:0px;
    position:relative;
    transition:all 0.5s ease;
    filter:${props => props.hoverState? 'grayscale(0)':'grayscale(0)'};
`;
const TileImageOverlay = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;    
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
    padding:15px;
`;
const Tag = styled.div`
    min-width:110px;
    text-decoration:none;
    padding:5px 10px 5px 10px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, .30);  
    backdrop-filter: blur(5px);
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-bottom:auto;
`;
const Content = styled.div`
    padding:10px 15px 10px 15px;
    background:#fff;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    flex-direction:column;
    @media screen and (max-width: 479px) {
        padding:15px 15px 20px 15px;
    }
`;
const Title = styled.h1`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin-bottom:10px;
`;
const Excerpt = styled.div`
    margin-bottom:15px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    max-width:90%;
    @media screen and (max-width: 479px) {
        max-width:100%;
        font-size: 16px;
    }
`;

const BlogTile = (props) => {
    const [hoverState, setHoverState] = useState(false);
    const toggleHoverIn= () =>{
        setHoverState(true)
    }
    const toggleHoverOut= () =>{
        setHoverState(false)
    }
    useEffect(() => {
        setHoverState(false)
      }, []);
    return (        
        <Tile to={"/resources/"+props.data.slug} onMouseEnter={toggleHoverIn} onMouseLeave={toggleHoverOut}>
                <TileImageWrap>
                    <TileImage src={props.data.featuredImage.node.mediaItemUrl} hoverState={hoverState}></TileImage>
                    <TileImageOverlay>
                        <Tag>{props.data.categories.nodes[0].name}</Tag>
                    </TileImageOverlay>
                </TileImageWrap>
                <Content>
                    <Title>{props.data.title}</Title>
                    <Excerpt dangerouslySetInnerHTML={{ __html: props.data.excerpt.substr(0,130)+"..." }}></Excerpt>
                    <SectionButtonExternal padding={"4px 33px 4px 33px"} left={"auto"} right={"0px"} fontSize={"14px"}>Read More</SectionButtonExternal>
                </Content>
        </Tile>   
    );
};

export default BlogTile;