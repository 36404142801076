import * as React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import SectionHeading from "../components/SectionHeading"
import Background from "../images/section-background.png"
import BlogTile from "../components/BlogTile"
import LogoBlock from "../components/LogoBlock"
import Favicon from "../images/w-health-favicon.png"
const PageWrap = styled.div`
    display:flex;
    flex-direction:column;
    position:relative;
    // overflow:hidden;
`;
const LogoContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    padding: 0 2.5% 0 2.5%;
`;
const BlogWrap = styled.div`
    display:flex;
    flex-direction:column;
    padding: 80px 2.5% 0px 2.5%;
    width:100%;
    height:auto;
    position:relative;
    @media screen and (max-width: 991px) {
      padding: 0px 2.5% 0px 2.5%;
    }
`;

const BackSection = styled.div`
    position:absolute;
    bottom:0;
    left:0;
    right:0%;
    top:auto;
    height:auto;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height:140vh;
    background-image:url(${Background});
    background-size:contain;
    background-position:50% 50%;
    background-repeat: no-repeat;
    opacity:0.8;
    @media screen and (max-width: 991px) {
        
    }
    @media screen and (max-width: 479px) {
      
    }
`;
const TileWrap = styled.div`
    width:100%;
    height:auto;
    padding:0px 1.5% 80px 1.5%;    
    display:flex;
    flex-wrap:wrap;
    position:relative;
    overflow:hidden;
    @media screen and (max-width: 479px) {
      padding:0px 1.5% 40px 1.5%;   
    }
`;
const Breadcrumb = styled.h2`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:20px 0 0px 0;
    @media screen and (max-width: 991px) {
        margin-bottom:10vh;
    }
    @media screen and (max-width: 479px) {
      margin:40px 0 5vh 0;
  }
`;
export default function Resources({ data }) {
  const AllPosts = data.allWpPost.nodes;
  return (
    <Layout>
      <Seo title="Resources" url="https://whealthventures.com/resources" image={Favicon} />
      <PageWrap>
        <LogoContainer>
          <LogoBlock></LogoBlock>
          <Breadcrumb>Resources</Breadcrumb>
        </LogoContainer>
        <BlogWrap>
          <SectionHeading>Blog & Articles</SectionHeading>
        </BlogWrap>
      </PageWrap>
      <TileWrap>
        {AllPosts.map((post, index) => (
          <BlogTile key={index} data={post}></BlogTile>
        ))}
        <BackSection></BackSection>
      </TileWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPost(sort: {order: DESC, fields: date}) {
        nodes {
          title
          id
          content
          date(formatString: "Do MMMM YYYY")
          slug
          excerpt
          categories {
            nodes {
              slug
              name
            }
          }
          tags {
            nodes {
              slug
              name
            }
          }
          author {
            node {
              name
              slug
            }
          }
          blogFields {
            readingTime
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
    }
}
`
